<template>
  <b-card no-body>
    <div class="m-2">
      <b-row class="justify-content-end">
        <!-- <b-col
          cols="12"
          md="9"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              @keyup="GetTotal"
              class="d-inline-block mr-1"
              :placeholder="$t('globalActions.find')"
            />
          </div>
        </b-col> -->

        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
        >
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="outline-success"
            pill
          >
            Total : {{ formatCurrency(totalList, false) }}
          </b-button>
        </b-col>
      </b-row>
      <!-- <div class="demo-inline-spacing">
        <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="outline-success"
            pill
          >
            Total : {{formatCurrency(totals, false)}}
          </b-button>
      </div> -->
    </div>

    <b-table
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :fields="tableColumns"
      :items="itemLists"
      responsive
      primary-key="id"
      show-empty
      :busy="isBusy"
      :empty-text="$t('globalSingular.noMatching')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #table-busy>
        <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
          <div class="text mb-1">
            <b-spinner class="align-middle" />
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>
      </template>

      <template #head()="{ field }">
        <table-search-column
          :field="field"
          :fields="tableColumns"
          :focused-field="focusedSearchField"
          @set-focused-field="(value) => focusedSearchField = value"
          @search-data="(params) => searchData(params)"
        />
      </template>

      <!-- Column: Invoice Number -->
      <template #cell(no_inv)="{item}">
        <b-link
          :to="{ name: 'apps-transactions-purchase-payment-per-voucher-add-outs', params: { id: item.id_invoice, vendor: item.contact_id } }"
          class="font-weight-bolder"
        >
          <span class="font-weight-bolder d-block text-nowrap">
            {{ item.no_inv }}
          </span>
        </b-link>
      </template>

      <!-- Column: Invoice Amount -->
      <template #cell(invoice_amount)="{ item }">
        <span
          :class="[
            'font-weight-bolder',
            'd-block',
            'text-nowrap',
            (item.invoice_amount > 0) ? 'text-success' : 'text-danger'
          ]"
        >
          {{ formatCurrency(item.invoice_amount, false) }}
        </span>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('globalSingular.showing') }} {{ dataMeta.from }} {{ $t('globalSingular.to') }} {{ dataMeta.to }} {{ $t('globalSingular.of') }} {{ dataMeta.of }} {{ $t('globalSingular.entries') }}</span>
        </b-col>

        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalList"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="refListTable"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

  </b-card>
</template>


<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BSpinner,
  BLink,
  BPagination
} from 'bootstrap-vue'
import TableSearchColumn from "@/components/TableSearchColumn.vue"

import useListTablePage from '@/comp-functions/useListTablePage'
import { formatCurrency } from '@/utils/formatter'
import useLocalization from '@/comp-functions/utils/useLocalization'
// import useHttp from '@/comp-functions/useHttp'
import { ref } from '@vue/composition-api'
// import { BBadge } from 'bootstrap-vue'
import { BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'


export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BSpinner,
    BLink,
    // BBadge,
    BButton,
    BPagination,
    TableSearchColumn
  },

  directives: {
    Ripple,
  },

  setup () {
    const { $t }   = useLocalization()

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      focusedSearchField,
      fetchLists,
      loadPage,
      changePerPage,
      searchData,
    } = useListTablePage({ url: 'transaction/purchase/posting-ap-payment-outstanding' })

    const tableColumns = ref([
      {
        key: 'invoice_no',
        label: $t('globalSingular.invoiceNo'),
        thClass: 'px-1',
        search: { type: 'text', value: '' }
      },
      {
        key: 'invoice_amount',
        label: $t('globalSingular.amount'),
        thClass: 'px-1',
        search: { type: 'number', value: 0 }
      },
      {
        key: 'office_name',
        label: $t('globalSingular.branch'),
        thClass: 'px-1',
        search: {
          type: 'select',
          value: '',
          key: 'office_id',
          url: 'value/getBranch'
        }
      },
      {
        key: 'code',
        label: $t('globalSingular.formPaymentNo'),
        thClass: 'px-1',
        search: { type: 'text', value: '' }
      },
      {
        key: 'vendor',
        label: $t('globalSingular.vendor'),
        thClass: 'px-1',
        thStyle: { minWidth: '300px' },
        formatter: (_, __, item) => (item.vendor_code
          ? `${item.vendor_code} - ${item.vendor}`
          : item.vendor),
        search: {
          type: 'select',
          value: '',
          key: 'contact_id',
          url: 'value/contact/all'
        }
      }
    ])

    return {
      formatCurrency,
      searchQuery,
      tableColumns,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      focusedSearchField,
      fetchLists,
      loadPage,
      changePerPage,
      searchData,
    }
  },


  computed: {
    companyActive () {
      return this.$store.state.user.currentCompany
    }
  }
}
</script>

